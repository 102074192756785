if (process.env.NODE_ENV !== 'development') {
  global.console.clear();
  /**
   * Add the warning message in console to alert the user about XSS Attack.
   */
  const warningTitleCSS = 'color:red; font-size:48px; font-weight: bold; -webkit-text-stroke: 1px black;';
  const warningDescCSS = 'font-size: 18px;';

  console.log('%cStop!', warningTitleCSS);
  console.log(
    '%cThis is a browser feature intended for developers. If someone told you to copy and paste something here to enable a NLG feature or "hack" someone\'s account, it is a scam and will give them access to your NLG account.',
    warningDescCSS,
  );
  global.console.log = () => {};
  global.console.error = () => {};
  global.console.info = () => {};
  global.console.warn = () => {};
}
import('./App');
